import './Disclaimer.css';

import { CustomModalContent } from '../CustomComponents';
import { useDisclaimerContext } from '../../lib/contexts';
import { useState } from 'react';


function Disclaimer() {
  const { disclaimerContext } = useDisclaimerContext();
  const { privacyPolicy, termsOfUse } = disclaimerContext;

  const [isOpen, setIsOpen] = useState({
    privacyPolicy: false,
    optOut: false,
  });
  const openModal = (key) => setIsOpen({ ...isOpen, [key]: true });
  const closeModal = (key) => setIsOpen({ ...isOpen, [key]: false });

  const year = new Date().getFullYear();

  return (
    <div className="disclaimer__container">
      <CustomModalContent
        title="Privacy Policy"
        isOpen={isOpen.privacyPolicy}
        onClose={() => closeModal('privacyPolicy')}
        content={privacyPolicy.data?.html}
        isHtml
      />
      <CustomModalContent
        title="Terms Of Use"
        isOpen={isOpen.termsOfUse}
        onClose={() => closeModal('termsOfUse')}
        content={termsOfUse.data?.html}
        isHtml
      />

      <div className="disclaimer__menu">
        <div className="disclaimer__copyright disclaimer__copyright-hidden">
          &copy; {year} dabellawindows.us All rights reserved.
        </div>

        <div className="disclaimer__menu-text">
          
        </div>
        <div className="disclaimer__menu-options">
          <a href="https://dabella.us/terms-conditions-privacy/" target="_blank">
            Terms, Conditions and Privacy
          </a>
          <button
            className="disclaimer__button-as-link"
            onClick={() =>
              window.open(process.env.REACT_APP_DO_NOT_SELL_MY_INFO, '_blank')
            }
          >
            Do Not Sell My Info
          </button>
          <button
            className="disclaimer__button-as-link"
            onClick={() => window.open(process.env.REACT_APP_OPT_OUT, '_blank')}
          >
            Opt Out
          </button>
        </div>
      </div>
      <div className="disclaimer__copyright">
        &copy; {year} dabellawindows.us All rights reserved.
      </div>
    </div>
  );
}

export default Disclaimer;
