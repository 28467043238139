import FooterLogo from '../../assets/img/logo-footer.webp';
import React from 'react';
function FourthSection() {
  return (
    <>
      <section className="contactArea py-5">
        <div className="container text-center">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-5 col-sm-6 col-xs-12 brand-logos">
              <img src={FooterLogo} alt="logo" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FourthSection;
