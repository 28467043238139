import { CustomInput, CustomPhoneInput } from '../../CustomComponents';
import {
  EMAIL_ERROR_MESSAGE,
  PHONE_ERROR_MESSAGE,
  US_PHONE_MASK,
} from '../../../lib/constants';
import {
  getErrorMessage,
  validateEmail,
  validatePhoneNumber,
} from '../../../lib/utils';
import isEmpty from 'lodash/isEmpty';

import { useFormContext } from 'react-hook-form';
import { useFormDataContext } from '../../../lib/contexts';
import { useRef } from 'react';
import { useScrollToTop } from '../../../lib/hooks';

function FormStep3() {
  const { register, trigger, handleSubmit, clearErrors, formState } =
    useFormContext();
  const { errors } = formState;
  const { updateFormData, formData, updateStep } = useFormDataContext();
  const emailRef = useRef();
  const phoneRef = useRef();
  useScrollToTop();

  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['email', 'phone']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        email: emailRef,
        phone: phoneRef,
      };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      updateStep(formData.step + 1);
    }
  });

  useScrollToTop();

  return (
    <div className="form-step3">
      <div className="form-step">
        <div className="form-step__label">Let us know how we can reach you</div>

        <CustomInput
          type="text"
          placeholder="Email Address"
          name="email"
          register={register}
          onInputChange={updateFormData}
          validation={{
            required: EMAIL_ERROR_MESSAGE,
            validate: validateEmail,
          }}
        />

        <CustomPhoneInput
          name="phone"
          placeholder="Phone Number (999)999-9999"
          mask={US_PHONE_MASK}
          inputMode="numeric"
          autoComplete="off"
          validation={{
            required: PHONE_ERROR_MESSAGE,
            validate: validatePhoneNumber,
          }}
        />
        {!isEmpty(errors) && (
          <div className="form-step__error-box">{getErrorMessage(errors)}</div>
        )}
        <input
          onClick={handleClickContinue}
          className="form-step__btn"
          type="button"
          value="Continue"
        />

        <input
          onClick={() => {
            clearErrors();
            updateStep(formData.step - 1);
          }}
          className="form-step__btn-back"
          type="button"
          value="Back"
        />
      </div>
    </div>
  );
}

export default FormStep3;
