import './CustomSubmit.css';

import { CustomModalContent } from '../index';
import { useDisclaimerContext } from '../../../lib/contexts';
import { useState } from 'react';

function CustomSubmit() {
  const { disclaimerContext } = useDisclaimerContext();
  const { partnerList, termsOfUse, privacyPolicy } = disclaimerContext;

  const [isOpen, setIsOpen] = useState({
    partnerList: false,
    termsOfUse: false,
    privacyPolicy: false,
  });

  const openModal = (key) => setIsOpen({ ...isOpen, [key]: true });
  const closeModal = (key) => setIsOpen({ ...isOpen, [key]: false });

  return (
    <div className="custom-submit__container">
      <CustomModalContent
        title="Partner List"
        isOpen={isOpen.partnerList}
        onClose={() => closeModal('partnerList')}
        content={partnerList.data?.html}
        isHtml
      />
      <CustomModalContent
        title="Terms Of Use"
        isOpen={isOpen.termsOfUse}
        onClose={() => closeModal('termsOfUse')}
        content={termsOfUse.data?.html}
        isHtml
      />
      <CustomModalContent
        title="Privacy Policy"
        isOpen={isOpen.privacyPolicy}
        onClose={() => closeModal('privacyPolicy')}
        content={privacyPolicy.data?.html}
        isHtml
      />
      <div>
        <label className="custom-submit__consent-text" id="leadid_tcpa_disclosure_label">
          <input type="hidden" id="leadid_tcpa_disclosure"/>
          By clicking "Get Quote Now", you authorize DaBella, who can help you learn more about Roofing, Siding, Window, Bath and other home remodeling projects, to call, SMS, and email you at the telephone number(s) and email provided, and you understand that we may use automated phone technology system (ATDS) including text messaging to contact you. You understand that your consent is not required to purchase products or services, and you understand that you may revoke your consent at any time by calling DaBella at 855-322-3552. <a href="https://dabella.us/terms-conditions-privacy/" target="_blank">View our Privacy Policy.</a>
        </label>
        <button type="submit" className="custom-submit__btn">
          Get Quote Now
        </button>
      </div>
    </div>
  );
}

export default CustomSubmit;
