import {
  FIRST_NAME_ERROR_MESSAGE,
  LAST_NAME_ERROR_MESSAGE,
  NAME_PATTERN,
} from '../../../lib/constants';

import { getErrorMessage, onlyLettersInput } from '../../../lib/utils';
import { CustomInput } from '../../CustomComponents';
import isEmpty from 'lodash/isEmpty';
import { useFormContext } from 'react-hook-form';
import { useFormDataContext } from '../../../lib/contexts';
import { useRef } from 'react';
import { useScrollToTop } from '../../../lib/hooks';

function FormStep2() {
  const { register, trigger, handleSubmit, clearErrors, formState } =
    useFormContext();
  const { errors } = formState;
  const { updateFormData, formData, updateStep } = useFormDataContext();
  const firstnameRef = useRef();
  const lastnameRef = useRef();
  useScrollToTop();

  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['firstName', 'lastName']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        firstname: firstnameRef,
        lastname: lastnameRef,
      };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      updateStep(formData.step + 1);
    }
  });

  useScrollToTop();
  return (
    <div className="form-step2">
      <div className="form-step">
        <div className="form-step__label">Who are we speaking with?</div>

        <CustomInput
          type="text"
          placeholder="First Name"
          name="firstname"
          value={formData.firstname}
          onInput={onlyLettersInput}
          register={register}
          onInputChange={updateFormData}
          validation={{
            required: FIRST_NAME_ERROR_MESSAGE,
            pattern: {
              value: NAME_PATTERN,
              message: FIRST_NAME_ERROR_MESSAGE,
            },
          }}
        />

        <CustomInput
          type="text"
          placeholder="Last Name"
          name="lastname"
          onInput={onlyLettersInput}
          register={register}
          onInputChange={updateFormData}
          validation={{
            required: LAST_NAME_ERROR_MESSAGE,
            pattern: {
              value: NAME_PATTERN,
              message: LAST_NAME_ERROR_MESSAGE,
            },
          }}
        />
        {!isEmpty(errors) && (
          <div className="form-step__error-box">{getErrorMessage(errors)}</div>
        )}
        <input
          onClick={handleClickContinue}
          className="form-step__btn"
          type="button"
          value="Continue"
        />

        <input
          onClick={() => {
            clearErrors();
            updateStep(formData.step - 1);
          }}
          className="form-step__btn-back"
          type="button"
          value="Back"
        />
      </div>
    </div>
  );
}

export default FormStep2;
