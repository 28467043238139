import React from 'react';

import image1 from '../../assets/img/qrTop500.webp';
import image2 from '../../assets/img/guildmaster-award.webp';
import image3 from '../../assets/img/Home-Advisor-Top-Rated.webp';
import image4 from '../../assets/img/Angi_SSA23_Logo.svg';
import image5 from '../../assets/img/best-pick-report.webp';
import image6 from '../../assets/img/Inc5000_Medallion_Color.webp';

function SecondSection() {
  return (
    <section className="logosArea py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10 col-sm-12 col-xs-12 mx-auto">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <div className="slider logos-slider">
                  <div className="sliderBox">
                    <img src={image1} alt="" />
                  </div>

                  <div className="sliderBox">
                    <img src={image2} alt="" />
                  </div>

                  <div className="sliderBox">
                    <img src={image3} alt="" />
                  </div>

                  <div className="sliderBox">
                    <img src={image4} alt="" />
                  </div>

                  <div className="sliderBox">
                    <img src={image5} alt="" />
                  </div>

                  <div className="sliderBox">
                    <img src={image6} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SecondSection;
