import { useEffect } from 'react';

const useScrollToTop = () => {
  const anchor = document.getElementById("navbar");
  useEffect(() => {
    const scrollToTop = () => {
      if(anchor){
        anchor.scrollIntoView({ behavior: "auto" });
      }
    };

    setTimeout(scrollToTop, 100);
  }, []);
};

export default useScrollToTop;
