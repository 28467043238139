import React from 'react';

function ThirdSection() {
  return (
    <section className="heroArea py-5">
      <div className="container">
        <div className="row py-5">
          <div className="col-lg-9 col-md-10 col-sm-12 col-xs-12 py-5 mx-auto text-center">
            <h2 className="text-white mb-4">
              AT DABELLA YOU ARE NOT “JUST” A CUSTOMER, YOU ARE A FAMILY WE
              SERVE
            </h2>
            <a href="#formArea" className="btnCta">
              REQUEST A QUOTE
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ThirdSection;
