import './TopBarAndFooter.css';

import DaBellaLogo from '../../assets/img/logo.svg';
import { LOCAL_PHONE_NUMBER } from '../../lib/constants';
import MainForm from '../MainForm/MainForm';
import { showELocalPhone } from '../../lib/utils';
import { useGeoLocationContext } from '../../lib/contexts';

function TopBarAndFooter({ className = '', showNumber = false }) {
  const { postal, country } = useGeoLocationContext();

  const onClickPhoneNumber = () => {
    window.open(`tel:${LOCAL_PHONE_NUMBER}`, '_self');
  };

  return (
    <>
      <header className="header py-3" id="navbar">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
              <img src={DaBellaLogo} alt="Logo" className="image" />
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default TopBarAndFooter;
