import './MainForm.css';
import './Steps/steps.css';
import {
  DEFAULT_MAIN_SUBTITLE,
  DEFAULT_MAIN_TITLE,
  LOCAL_PHONE_NUMBER,
  THANK_YOU_ALTERNATIVE_ROUTE,
} from '../../lib/constants';
import { FormProvider, useForm } from 'react-hook-form';
import { Step1, Step2, Step3, Step4 } from './Steps';
import { showELocalPhone, submitForm } from '../../lib/utils';
import {
  useAppContext,
  useFormDataContext,
  useLendersContext,
} from '../../lib/contexts';
import { CustomLoader } from '../CustomComponents';
import FourthSection from './FourthSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import img1 from '../../assets/img/loader-4step-1.webp';
import img2 from '../../assets/img/loader-4step-2.webp';
import img3 from '../../assets/img/loader-4step-3.webp';
import img4 from '../../assets/img/loader-4step-4.webp';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';


function MainForm({
  title = DEFAULT_MAIN_TITLE,
  subtitle = DEFAULT_MAIN_SUBTITLE,
}) {
  const navigate = useNavigate();
  const { setLendersContext } = useLendersContext();
  const { formData, clearStorage } = useFormDataContext();
  const { appContext } = useAppContext();
  const { step } = formData;
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm({ defaultValues: formData, mode: 'onSubmit' });
  const { formState } = methods;
  const { errors } = formState;
  const handleGlobalSubmit = async (data) => {
    setIsLoading(true);

    const result = await submitForm({ ...data, ...appContext });
    const lendersContextData = {
      name: `${formData.firstname} ${formData.lastname}`,
      zipCode: formData.zip,
    };

    if (result.status === 'matched') {
      lendersContextData.lenders = result.lenders;
    }

    if (result.pixel) {
      lendersContextData.pixel = result.pixel;
    }

    if (showELocalPhone(lendersContextData.zipCode, 'US')) {
      lendersContextData.eLocalPhone = LOCAL_PHONE_NUMBER;
    }

    setLendersContext(lendersContextData);

    const route = THANK_YOU_ALTERNATIVE_ROUTE;

    clearStorage();
    navigate(route);
    setIsLoading(false);
  };

  return (
    <>
      <CustomLoader isLoading={isLoading} />

      <section className="formArea py-5" id="formArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
              <h2 className="text-white mt-4 mb-5">
                Potentially Save Thousands On Replacing Your Windows With Our 25% Off Offer!
              </h2>
              {step === 1 && (
                <>
                  <img src={img1} className="stepImage" />
                </>
              )}
              {step === 2 && (
                <>
                  <img src={img2} className="stepImage" />
                </>
              )}
              {step === 3 && (
                <>
                  <img src={img3} className="stepImage" />
                </>
              )}
              {step === 4 && (
                <>
                  <img src={img4} className="stepImage" />
                </>
              )}
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleGlobalSubmit)}>
                  <div className="main-form__step-container mx-auto mt-5">
                    {step === 1 && <Step1 />}
                    {step === 2 && <Step2 />}
                    {step === 3 && <Step3 />}
                    {step === 4 && <Step4 />}
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </section>
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <section className="topBar">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
          <p>
            Dabella is not affiliated with any government grant or free window programs, all projects come at a cost to the consumer
          </p>
        </div>
      </section>
    </>
  );
}

export default MainForm;
