import './ThankYouAlternative.css';

import { useConditionalNavigation, useScrollToTop } from '../../lib/hooks';

//import GWTLogo from '../../assets/img/logo.png';
import parse from 'html-react-parser';
import { useLendersContext } from '../../lib/contexts';
import { useNavigate } from 'react-router-dom';

function ThankYouAlternative() {
  const { name, eLocalPhone, pixel } = useLendersContext();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  useConditionalNavigation(!name, '/');
  useScrollToTop();
  const parsedPixel = parse(pixel || '');

  return (
    <div className="thank-you-alternative__container">
      {pixel && (
        <div className="thank-you-alternative-lenders__pixel">
          {parsedPixel}
        </div>
      )}
      <div className="thank-you-alternative__box thank-you-alternative__main-box">
        {/*<div className="thank-you-alternative__logo-container">
          <img
            src={GWTLogo}
            alt="Logo"
            className="thank-you-alternative__logo"
          />
      </div>*/}

        <div className="thank-you-alternative__main-content">
          <div className="thank-you-alternative__main-content-title">
            Thank You
          </div>
          <div className="thank-you-alternative__main-content-text">
            Thank You for your request {name}, one of our representatives will be contacting you as soon as possible!
          </div>
        </div>
      </div>
      <div className="thank-you-alternative__box thank-you-alternative__phone-box">
        <div className="thank-you-alternative__main-content-title">
          Don&apos;t Wait Any Longer!
        </div>
        <div className="thank-you-alternative__main-content-text">
          Call us now at{' '}
          <a
            href={`tel:844-676-0885`}
            target="_blank"
            rel="noopener noreferrer"
            className="thank-you-alternative__phone-link"
          >
            {eLocalPhone || '844-676-0885'}
          </a>{' '}
          and let&apos;s get started on your windows project today!
        </div>
      </div>
      <div className="thank-you-alternative__box thank-you-alternative__home-box">
        <div className="thank-you-alternative__main-content-title">
          Ready for more options?
        </div>
        <div className="thank-you-alternative__main-content-text">
          We can handle most of your home improvement project needs! Click below to find out more!
        </div>
        <a
          href="https://dabella.us/"
          target="_blank"
          className="thank-you-alternative__main-content-btn"
        >
          Explore More Choices
          </a>
      </div>
    </div>
  );
}

export default ThankYouAlternative;
