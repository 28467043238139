import {
  ADDRESS_ERROR_MESSAGE,
  ADDRESS_PATTERN,
  ADDRESS_PATTERN_ERROR_MESSAGE,
} from '../../../lib/constants';
import { CustomInput, CustomSubmit } from '../../CustomComponents';
import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { getErrorMessage } from '../../../lib/utils';
import isEmpty from 'lodash/isEmpty';
import { useFormContext } from 'react-hook-form';
import { useFormDataContext } from '../../../lib/contexts';
import { useScrollToTop } from '../../../lib/hooks';

function FormStep4() {
  const { register, trigger, clearErrors, formState } = useFormContext();
  const { errors } = formState;
  const { updateFormData, formData, updateStep } = useFormDataContext();
  const addressRef = useRef();
  useScrollToTop();

  useEffect(() => {
    ReactGA.event({
      category: 'GWT Landing',
      action: 'GWT Step 4 - Address Info',
      label: 'GWT Landing',
    });
  }, []);

  return (
    <div className="form-step3">
      <div className="form-step">
        <div className="form-step__label">What is your home address?</div>

        <CustomInput
          type="text"
          placeholder="Street Address"
          name="address"
          register={register}
          onInputChange={updateFormData}
          validation={{
            required: ADDRESS_ERROR_MESSAGE,
            pattern: {
              value: ADDRESS_PATTERN,
              message: ADDRESS_PATTERN_ERROR_MESSAGE,
            },
          }}
        />
        {!isEmpty(errors) && (
          <div className="form-step__error-box">{getErrorMessage(errors)}</div>
        )}
        <CustomSubmit />
        <input
          onClick={() => {
            clearErrors();
            updateStep(formData.step - 1);
          }}
          className="form-step__btn-back"
          type="button"
          value="Back"
        />
      </div>
    </div>
  );
}

export default FormStep4;
